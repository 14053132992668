import "./App.css";
import { FiWifi } from "react-icons/fi";

function App() {
  const handleClick = () => {
    window.location =
      "http://192.168.10.254:880/cgi-bin/hotspot_login.cgi" +
      "?" +
      window.location.search.substr(1);
  };

  return (
    <div className="App">
      <h1>
        <img src="/logo.png" alt="logo"></img>
        <span>
          Zavod za fizikalnu medicinu i rehabilitaciju
          <br /> Dr Miroslav Zotović
        </span>
      </h1>
      {/* <div className="text">
        <h2>Dobrodošli</h2>
        <h2>Welcome</h2>
        <h2>Willkommen</h2>
        <h2>Välkommen</h2>
      </div> */}
      <FiWifi onClick={() => handleClick()} className="wifi" color={"white"} />
    </div>
  );
}

export default App;
